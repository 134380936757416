import challengesService from "@/services/challenges";

export default {
    computed: {
        currentVerifyPhone() {
            const actualVerifyPhone = this.$store.getters["currentVerifyPhone/currentVerifyPhone"]; 
            if (actualVerifyPhone != null)
                return actualVerifyPhone;
            else 
                return {
                    transactionId: null,
                    phone: null
                };
        }
    },
    methods: {
        async startVerifyPhone() {
            const initialData = {
                transactionId: null,
                phone: null
            };
            return this.createOrUpdateVerifyPhone(initialData);
        },
        async createOrUpdateVerifyPhone(recoveryData) {
            return this.$store.dispatch("currentVerifyPhone/createOrUpdate", recoveryData);
        },
        clearVerifyPhone() {
            return this.$store.dispatch("currentVerifyPhone/clear");
        },
        async sendOtpToPhone(phoneNumber) {
            const message = { 
                messagesInfo : [
                {
                    type: "sms",
                    address: `+569${phoneNumber}`,
                    attributes: [
                        {
                            key: "Nombre",
                            value: "Se debe cambiar Api Challenges"
                        }
                    ]
                }
            ]};
            
            try {
                const sendOtpResult = await challengesService.sendOtp(message); 

                const actualVerifyPhone = this.currentVerifyPhone;
                actualVerifyPhone.transactionId = sendOtpResult.data.transactionId;
                await this.createOrUpdateVerifyPhone(actualVerifyPhone);

                return {
                    success: true
                }
            }
            catch(error) {
                if (error.response && error.response.status == 400) 
                    return {
                        success: false,
                        isValidPhoneNumber: false,
                        theError: null
                    } 
                else 
                    return {
                        success: false,
                        isValidPhoneNumber: false,
                        theError: error
                    }
            }
        },
        async resendOtpToPhone() {
            const resendOtpData = {                        
                transactionId: this.currentVerifyPhone.transactionId
            };

            try {
                await challengesService.resendOtp(resendOtpData);
                return {
                    success: true
                }
            } 
            catch(error) {
                if (error.response && error.response.status == 400) 
                    return {
                        success: false,
                        isValidPhoneNumber: false,
                        theError: null
                    } 
                else 
                    return {
                        success: false,
                        isValidPhoneNumber: false,
                        theError: error
                    }
            }
        }
    }
};