import Vue from "vue";
import axios from "axios";
import axiosRetry from "axios-retry";
import store from "@/store/index";

const apiChallenges = axios.create({
  baseURL: `${Vue.prototype.$Settings.all.challengesApi.url}/abifddc`,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": `${Vue.prototype.$Settings.all.challengesApi.key}`,
    "Authorization-Middleware": `Basic ${Vue.prototype.$Settings.all.jwt}`,
    "Execution-Mode": `${Vue.prototype.$Settings.all.excecutionMode}`,
  },
});

axiosRetry(apiChallenges, {
  retries: 3,
  shouldResetTimeout: true,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

apiChallenges.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      store.dispatch(
        "notifications/addError",
        "Red no disponible. Vuelva a intentar"
      );
    }
    return Promise.reject(error);
  }
);

export default {
  sendOtp(messagesInfo) {
    return apiChallenges.post("/otp/SendOtp", messagesInfo);
  },
  resendOtp(transactionId) {
    return apiChallenges.post("/otp/ResendOtp", transactionId);
  },
  verifyOtp(verifyOtpData) {
    return apiChallenges.post("/otp/verifyOtp", verifyOtpData);
  },
};
