import accountsService from "@/services/accounts"; 

import allConstantsMixin from "@/mixins/allConstants";

export default {
    mixins: [allConstantsMixin],
    computed: {
        currentRegistrationInProcess() {
            return this.$store.getters["currentRegistration/currentRegistration"];
        },
    },
    methods: {
        startCurrentRegistration(userType) {
            return this.currentRegistrationCreateOrUpdate({
                userType: userType,
                currentStep: this.allConstants.registration.registrationSteps.collectBasicData
            });
        },
        currentRegistrationCreateOrUpdate(claim) {
            return this.$store.dispatch("currentRegistration/createOrUpdate", claim);
        },
        currentRegistrationInCreationClear() {
            return this.$store.dispatch("currentRegistration/clear");
        },
        currentRegistrationInCreationGoForeward() {
            const actualRegistration = this.currentRegistrationInProcess;
            actualRegistration.currentStep++;
            return this.currentRegistrationCreateOrUpdate(actualRegistration);
        },
        currentRegistrationInCreationGoBackward() {
            const actualRegistration = this.currentRegistrationInProcess;
            actualRegistration.currentStep--;
            return this.currentRegistrationCreateOrUpdate(actualRegistration);
        },
        createAccount() {
            const accountData = {
                identificationNumber: this.currentRegistrationInProcess.collectedBasicData.identificationNumber,
                name: this.currentRegistrationInProcess.collectedBasicData.name,
                surname: this.currentRegistrationInProcess.collectedBasicData.surname,
                email: this.currentRegistrationInProcess.collectedBasicData.email,
                phone: this.currentRegistrationInProcess.collectedBasicData.phone.toString(),
                cellphone: this.currentRegistrationInProcess.collectedBasicData.cellphone.toString(),
                address: {
                    region: this.currentRegistrationInProcess.collectedAddressData.region,
                    city: this.currentRegistrationInProcess.collectedAddressData.province,
                    commune: this.currentRegistrationInProcess.collectedAddressData.commune,
                    streetAddress: this.currentRegistrationInProcess.collectedAddressData.street,
                    apartment: this.currentRegistrationInProcess.collectedAddressData.apartment,
                    building: this.currentRegistrationInProcess.collectedAddressData.building
                },
                bank: {
                 code: "0"
                },
                roleId: this.allConstants.security.userRolesCodes.claimant,
                includeCredentialCreation: true,
                passwordForCredential: this.currentRegistrationInProcess.collectedBasicData.secret,
                userType: this.currentRegistrationInProcess.userType
            };

            return accountsService.create(accountData);
        }
    }
};