<template>
    <v-form v-model="form.isValid" @submit.prevent="enter">
        <section class="container-fluid g-px-140--lg g-px-50--sm">
            <div class="row">
                <div class="col-sm-12 col-lg-12 g-px-10">
                    <div class="form-group mb-0">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 font-color-text">
                                <p><img src="assets/img/map.svg"> Región</p>
                                <div class="form-group g-mb-1">
                                    <v-select outlined v-model.trim="formFields.region" :items="regions.map(region => {return {value: region.code, text: region.name}})" placeholder="Selecciona una de la lista" required :rules="formFields.regionRules" :disabled="regions.length == 0" @change="resetProvincesList"></v-select>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 font-color-text">
                                <p><img src="assets/img/map.svg"> Provincia</p>
                                <div class="form-group g-mb-1">
                                    <v-select outlined v-model.trim="formFields.provincia" :items="provinces.map(province => {return {value: province.code, text: province.name}})" placeholder="Selecciona una de la lista" required :rules="formFields.provinciaRules" :disabled="provinces.length == 0" @change="resetCommunesList"></v-select>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 font-color-text">
                                <p><img src="assets/img/map.svg"> Comuna</p>
                                <div class="form-group g-mb-1">
                                    <v-select outlined v-model.trim="formFields.comuna" :items="communes.map(commune => {return {value: commune.code, text: commune.name}})" placeholder="Selecciona una de la lista" required :rules="formFields.comunaRules" :disabled="communes.length == 0"></v-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group g-pt-40">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 font-color-text">
                                <p><img src="assets/img/pin.svg"> Dirección</p>
                                <div class="form-group g-mb-1">
                                    <div class="u-input-group-v2">
                                        <v-text-field outlined v-model.trim="formFields.calle" required :rules="formFields.calleRules" maxlength="50" ></v-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 font-color-text">
                                <p><img src="assets/img/pin.svg"> {{userType == allConstants.security.userTypes.person ? "Departamento" : "Piso"}} </p>
                                <div class="form-group g-mb-1">
                                    <div class="u-input-group-v2">
                                        <v-text-field outlined v-model.trim="formFields.departamento" required :rules="formFields.departamentoRules" maxlength="10" ></v-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-4 font-color-text">
                                <p><img src="assets/img/pin.svg"> {{userType == allConstants.security.userTypes.person ? "Block / Condominio / Villa" : "Oficina"}}  </p>
                                <div class="form-group g-mb-10">
                                    <div class="u-input-group-v2">
                                        <v-text-field outlined v-model.trim="formFields.block" required :rules="formFields.blockRules" maxlength="10" ></v-text-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        
        <section class="container-fluid text-center g-mb-60">
            <div class="row">
                <div class="col-6 col-xl-6 col-lg-6" v-if="showBackButton">
                    <v-btn class="btn btn-lg btn-secondary g-mr-10 g-mb-15" :disabled="disableBackButton" :loading="form.processing" @click="back">Volver</v-btn>
                </div>
                <div class="col-6 col-xl-6 col-lg-6" v-if="showMainButton">
                    <v-btn type="submit" class="btn-main" :disabled="!form.isValid" :loading="form.processing">Continuar</v-btn>
                </div>
            </div>
        </section>

    </v-form>
</template>

<script>
    import baseViewMixin from "@/mixins/baseView";

    export default {
        mixins: [baseViewMixin],
        props: {
            userType: {
                type: Number,
                required: true
            },
            showBackButton: {
                type: Boolean,
                required: false,
                default: false
            },
            disableBackButton: {
                type: Boolean,
                required: false,
                default: true
            },
            showMainButton: {
                type: Boolean,
                required: false,
                default: false
            },
            preloadData: {
                type: Object,
                required: false,
                default: null
            }
        },
        data() {
            return {
                formFields: {
                    region: "",
                    regionRules: [
                        value => !!value || "Requerido"
                    ],
                    provincia: "",
                    provinciaRules: [
                        value => !!value || "Requerido"
                    ],
                    comuna: "",
                    comunaRules: [
                        value => !!value || "Requerido"
                    ],
                    calle: "",
                    calleRules: [
                        value => !!value || "Requerido"
                    ],
                    departamento: "",
                    departamentoRules: [],
                    block: "",
                    blockRules: [],
                },
                allRegions: [],
                regions: [],
                provinces: [],
                communes:[]
            }
        },
        methods: {
            back() {
                this.$emit("back");
            },
            async enter() {
                this.initSignalProcessing();

                const collectedData = {
                    region: this.formFields.region,
                    province: this.formFields.provincia,
                    commune: this.formFields.comuna,
                    street: this.formFields.calle,
                    apartment: this.formFields.departamento,
                    building: this.formFields.block
                };

                this.$emit("enter", collectedData);
            },
            resetProvincesList() {
                this.formFields.provincia = "";
                this.formFields.comuna = "";

                const theRegion = this.allRegions.find(region => region.code == this.formFields.region);

                if (theRegion != undefined)
                    this.provinces = theRegion.provinces.map(province => { return {code: province.code, name: province.name} });

                this.communes = [];
            },
            resetCommunesList() {
                this.formFields.comuna = "";

                const theRegion = this.allRegions.find(region => region.code == this.formFields.region);
                if (theRegion != undefined)
                {
                    const theProvince = theRegion.provinces.find(provincia => provincia.code == this.formFields.provincia);
                    this.communes = theProvince.communes.map(commune => { return {code: commune.code, name: commune.name} });
                }
            }
        },
        async beforeMount () {
            this.initSignalLoading();

            //Get and prepare regions list
            const parametersRegions = this.$Parameters.all.find(parameter => parameter.type == "Regions");
            this.allRegions = parametersRegions.values

            this.regions = this.allRegions.map(region => { return {code: region.code, name: region.name} });

            //Preload data
            if (this.preloadData) {
                this.formFields.region = this.preloadData.region;
                this.formFields.provincia = this.preloadData.province;
                this.formFields.comuna = this.preloadData.commune;
                this.formFields.calle = this.preloadData.street;
                this.formFields.departamento = this.preloadData.apartment;
                this.formFields.block = this.preloadData.building;

                this.resetProvincesList();
                this.formFields.provincia = this.preloadData.province;

                this.resetCommunesList();
                this.formFields.comuna = this.preloadData.commune;
            }

            this.stopSignalLoading();
        },
    }
</script>

<style lang="scss" scoped>
  .font-color-name {
    color: #555;
    font-size:17.5px;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
  .font-color-text {
    font-weight: 400;
    font-size:  1rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    color: #555;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
  }
    .font-color-subtitle {
    font-weight: 400;
    font-size: 1rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
       color: #fff !important;
  }
  .mt-n1 {
  margin-top: -0.25rem !important;
}
</style>