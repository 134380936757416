<template>
    <div>
        <section class="g-bg-pos-center g-bg-size-cover" style="background-image: url(assets/img/fondo-ingreso-wide.jpg);height:300px;">
       <div class="container g-color-white text-center g-py-210"></div>
        </section>
        <section class="container-fluid g-px-100--lg mt-5">
            <h1 class="g-font-weight-500 g-mb-15 font-color-title">Registro {{currentRegistrationInProcess.userType == allConstants.security.userTypes.person ? "persona natural" : "persona jurídica"}}</h1>
            <hr class="g-my-40" style="border: 1px solid;color: #555;!important">
        </section>

        <section class="container-fluid g-px-100--lg mb-10">
            <div v-if="currentRegistrationInProcess.userType == allConstants.security.userTypes.person">
                <div class="row" v-if="currentRegistrationInProcess.currentStep == allConstants.registration.registrationSteps.collectBasicData">
                    <div class="col-sm-12 col-lg-12 g-px-10 text-center">
                        <img class="xs-display-none img-fluid" src="assets/img/nav-regis-pn-1.svg">
                        <img class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none" src="assets/img/nav-regis-pn-1-sm.svg">
                    </div>
                </div>
                <div class="row" v-if="currentRegistrationInProcess.currentStep == allConstants.registration.registrationSteps.collectAddressData">
                    <div class="col-sm-12 col-lg-12 g-px-10 text-center">
                        <img class="xs-display-none img-fluid" src="assets/img/nav-regis-pn-2.svg">
                        <img class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none" src="assets/img/nav-regis-pn-2-sm.svg">
                    </div>
                </div>
            </div>
            <div v-if="currentRegistrationInProcess.userType == allConstants.security.userTypes.business">
                <div class="row" v-if="currentRegistrationInProcess.currentStep == allConstants.registration.registrationSteps.collectBasicData">
                    <div class="col-sm-12 col-lg-12 g-px-10 text-center">
                        <img class="xs-display-none img-fluid" src="assets/img/nav-regis-pj-1.svg">
                        <img class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none" src="assets/img/nav-regis-pj-1-sm.svg">
                    </div>
                </div>
                <div class="row" v-if="currentRegistrationInProcess.currentStep == allConstants.registration.registrationSteps.collectAddressData">
                    <div class="col-sm-12 col-lg-12 g-px-10 text-center">
                        <img class="xs-display-none img-fluid" src="assets/img/nav-regis-pj-2.svg">
                        <img class="text-center img-fluid xs-display-block d-lg-none md-display-none sm-display-none" src="assets/img/nav-regis-pj-2-sm.svg">
                    </div>
                </div>
            </div>
        </section>

        <collectorBasicData 
            :userType="currentRegistrationInProcess.userType" 
            :showMainButton="true" 
            :showBackButton="true"
            :disableBackButton="true"
            :preloadData="currentRegistrationInProcess.collectedBasicData"
            @enter="endCollectBasicData" 
            v-if="currentRegistrationInProcess.currentStep == allConstants.registration.registrationSteps.collectBasicData" />

        <collectorAddressData 
            :userType="currentRegistrationInProcess.userType" 
            :showMainButton="true" 
            :showBackButton="true"
            :disableBackButton="false"
            @enter="endCollectAddressData" 
            @back="currentRegistrationInCreationGoBackward"
            v-if="currentRegistrationInProcess.currentStep == allConstants.registration.registrationSteps.collectAddressData" />

        <section class="container-fluid g-px-140--lg g-px-50--sm mb-15" v-if="form.processing">
            <div class="row">
                <div class="col-12">
                    <v-progress-linear indeterminate color="cyan"></v-progress-linear>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import collectorBasicData from "@/components/PublicSite/User/collectorBasicData";
    import collectorAddressData from "@/components/PublicSite/User/collectorAddressData";

    import baseViewMixin from "@/mixins/baseView";
    import currentUserMixin from "@/mixins/currentUser";
    import currentRegistrationMixin from "@/mixins/currentRegistration";

    export default {
        mixins: [baseViewMixin, currentUserMixin, currentRegistrationMixin],
        components: {
            collectorBasicData,
            collectorAddressData
        },
        methods: {
            async endCollectBasicData(collectedData) {
                let currentRegistrationInProcess = this.currentRegistrationInProcess;
                currentRegistrationInProcess.collectedBasicData = collectedData;

                await this.currentRegistrationCreateOrUpdate(currentRegistrationInProcess);
                await this.currentRegistrationInCreationGoForeward();
            },
            async endCollectAddressData(collectedData) {
                let currentRegistrationInProcess = this.currentRegistrationInProcess;
                currentRegistrationInProcess.collectedAddressData = collectedData;

                await this.currentRegistrationCreateOrUpdate(currentRegistrationInProcess);
                await this.currentRegistrationInCreationGoForeward();

                await this.create();
            },
            async create() {
                this.initSignalProcessing();

                await this.createAccount(); 

                await this.internalLogin(
                    this.currentRegistrationInProcess.collectedBasicData.identificationNumber, 
                    this.currentRegistrationInProcess.collectedBasicData.secret);

                await this.currentRegistrationInCreationClear();

                this.internalPostLogin();
            },
        },
    }
</script>

<style lang="scss" scoped>
 .font-color-title {
    color: #555;
    font-size:2.5rem;
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
  }
</style>