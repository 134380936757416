<template>
  <div>
    <v-form
      autocomplete="off"
      v-model="form.isValid"
      @submit.prevent="verifyPhone"
      ref="basicDataForm"
    >
      <section
        class="container-fluid g-px-140--lg g-px-50--sm"
        v-show="alert.show"
      >
        <v-alert border="bottom" colored-border type="warning" elevation="1">
          {{ alert.message }}
        </v-alert>
      </section>

      <section class="container-fluid g-px-140--lg g-px-50--sm">
        <div class="row">
          <div class="col-sm-12 col-lg-12 g-px-10">
            <div class="form-group mb-0">
              <div class="row">
                <div class="col-xl-4 col-lg-4 font-color-text">
                  <p>
                    <img src="assets/img/rut.svg" />
                    {{
                      userType == allConstants.security.userTypes.person
                        ? "Ingresa tu RUT"
                        : "Rut de la empresa"
                    }}
                  </p>
                  <div class="form-group g-mb-1">
                    <div class="u-input-group-v2">
                      <v-text-field
                        outlined
                        v-model.trim="formFields.rut"
                        required
                        :rules="formFields.rutRules"
                        :label="formFields.rut | formatRut"
                        :disabled="disableIdentificationNumber"
                        maxlength="9"
                        ref="initialFocus"
                        hint="Sin puntos ni guion. Incluir digito verificador"
                        @change="isARegisterdAccount(formFields.rut)"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 font-color-text">
                  <p>
                    <img src="assets/img/name.svg" />
                    {{
                      userType == allConstants.security.userTypes.person
                        ? "Nombres"
                        : "Razón social"
                    }}
                  </p>
                  <div class="form-group g-mb-1">
                    <div class="u-input-group-v2">
                      <v-text-field
                        outlined
                        v-model.trim="formFields.nombres"
                        required
                        :rules="formFields.nombresRules"
                        maxlength="30"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 font-color-text">
                  <p>
                    <img src="assets/img/name.svg" />
                    {{
                      userType == allConstants.security.userTypes.person
                        ? "Apellidos"
                        : "Nombre del representante"
                    }}
                  </p>
                  <div class="form-group g-mb-1">
                    <div class="u-input-group-v2">
                      <v-text-field
                        outlined
                        v-model.trim="formFields.apellidos"
                        required
                        :rules="formFields.apellidosRules"
                        maxlength="30"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="row">
                <div class="col-xl-4 col-lg-4 font-color-text">
                  <p>
                    <img src="assets/img/mail.svg" />
                    {{
                      userType == allConstants.security.userTypes.person
                        ? "Ingresa tu email"
                        : "Email"
                    }}
                  </p>
                  <div class="form-group g-mb-1">
                    <div class="u-input-group-v2">
                      <v-text-field
                        outlined
                        v-model.trim="formFields.email"
                        required
                        :rules="formFields.emailRules"
                        maxlength="50"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 font-color-text">
                  <p>
                    <img src="assets/img/telephone.svg" />
                    {{
                      userType == allConstants.security.userTypes.person
                        ? "Ingresa tu teléfono móvil"
                        : "Teléfono móvil"
                    }}
                  </p>
                  <div class="input-group g-mb-1">
                    <div class="u-input-group-v2">
                      <v-text-field
                        outlined
                        :label="`+56 9${formFields.telefonoCelular}`"
                        v-model="formFields.telefonoCelular"
                        required
                        :rules="formFields.telefonoCelularRules"
                        maxlength="8"
                        autocomplete="new-password"
                        :name="`phone_${Math.random()}`"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
                <div
                  class="col-xl-4 col-lg-4 font-color-text"
                  v-if="userType == allConstants.security.userTypes.business"
                >
                  <p><img src="assets/img/telephone.svg" /> Teléfono fijo</p>
                  <div class="input-group g-mb-1">
                    <div class="u-input-group-v2">
                      <v-text-field
                        outlined
                        :label="'+56 ' + formFields.telefonoFijo"
                        v-model.number="formFields.telefonoFijo"
                        required
                        :rules="formFields.telefonoFijoRules"
                        maxlength="9"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group mt-n1" v-if="askForPassword">
              <div class="row">
                <div class="col-xl-4 col-lg-4 font-color-text">
                  <p><img src="assets/img/password.svg" /> Contraseña</p>
                  <div class="form-group g-mb-1">
                    <div class="u-input-group-v2">
                      <v-text-field
                        :type="showPassword ? 'text' : 'password'"
                        outlined
                        v-model.trim="formFields.clave"
                        required
                        maxlength="20"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="claveErrors"
                        autocomplete="false"
                        @mousedown="showPassword = true"
                        @mouseup="showPassword = false"
                        @change="isValidPasswordPattern()"
                        @input="$v.formFields.clave.$touch()"
                        @blur="$v.formFields.clave.$touch()"
                      ></v-text-field>
                    </div>
                    <ul class="g-pt-1 caption">
                      <li>Entre 8 y 20 caracteres</li>
                      <li>Debe incluir mayusculas y minusculas</li>
                      <li>Debe incluir dígitos</li>
                      <li>Debe incluir caracteres especial</li>
                    </ul>
                  </div>
                </div>
                <div class="col-xl-4 col-lg-4 font-color-text">
                  <p>
                    <img src="assets/img/password.svg" /> Repetir Contraseña
                  </p>
                  <div class="form-group g-mb-1">
                    <div class="u-input-group-v2">
                      <v-text-field
                        :type="showPasswordConfirmacion ? 'text' : 'password'"
                        outlined
                        v-model.trim="formFields.claveConfirmacion"
                        required
                        maxlength="20"
                        :append-icon="
                          showPasswordConfirmacion ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :error-messages="claveConfirmacionErrors"
                        autocomplete="false"
                        @mousedown="showPasswordConfirmacion = true"
                        @mouseup="showPasswordConfirmacion = false"
                        @input="$v.formFields.claveConfirmacion.$touch()"
                        @blur="$v.formFields.claveConfirmacion.$touch()"
                      ></v-text-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="container-fluid text-center g-mb-60">
        <div class="row">
          <div class="col-6 col-xl-6 col-lg-6" v-if="showBackButton">
            <v-btn
              class="btn btn-lg btn-secondary g-mr-10 g-mb-10"
              :disabled="disableBackButton"
              :loading="form.processing"
              @click="back"
              >Volver</v-btn
            >
          </div>
          <div class="col-6 col-xl-6 col-lg-6" v-if="showMainButton">
            <v-btn
              type="submit"
              class="btn-main btn btn-lg u-btn-blue g-mr-10 g-mb-10"
              :disabled="
                !form.isValid || (this.askForPassword && this.$v.$invalid)
              "
              :loading="form.processing"
              >Continuar</v-btn
            >
          </div>
        </div>
      </section>

      <section
        class="container-fluid g-px-140--lg g-px-50--sm"
        v-show="alert.show"
      >
        <v-alert border="bottom" colored-border type="warning" elevation="1">
          {{ alert.message }}
        </v-alert>
      </section>
    </v-form>
    <v-dialog v-model="showDialogVerifyPhone" max-width="600" persistent>
      <v-card>
        <div class="pa-6 pt-1">
          <div class="headline text-center mb-4 mt-6">Validación</div>

          <v-alert v-if="alert.show" dense border="left" type="warning">
            {{ alert.message }}
          </v-alert>

          <div v-if="!otpSent">
            <p class="text-center mb-3 font-color-text">
              <label
                >Estamos enviando un código de 6 dígitos a su teléfono
                celular</label
              >
            </p>
            <v-progress-linear indeterminate color="cyan"></v-progress-linear>
          </div>
          <div v-else>
            <p class="text-center font-color-text">
              <label
                >Ingrese el código de 6 dígitos que enviamos a su teléfono
                celular</label
              >
            </p>
            <div
              class="g-px-50 text-center g-font-size-14 color-orange-link"
              v-if="
                !formOtp.processing &&
                  !oneTimePasswordExpired &&
                  !oneTimePasswordMaxRetries
              "
            >
              <router-link
                to="#"
                v-on:click.native="resendOtp()"
                :disabled="formOtp.processing"
                class="color-orange-link"
                >Volver a solicitar código</router-link
              >
            </div>
            <v-form
              v-model="formOtp.isValid"
              @submit.prevent="verifyOtp"
              ref="formVerifyOtp"
            >
              <v-text-field
                autocomplete="false"
                outlined
                v-model.trim="formOtp.oneTimePassword"
                :disabled="oneTimePasswordExpired || oneTimePasswordMaxRetries"
                required
                :rules="formOtp.oneTimePasswordRules"
                maxlength="6"
                class="otp-field"
              ></v-text-field>
              <v-row>
                <v-col cols="12" md="6" class="text-center"
                  ><v-btn
                    class="btn btn-lg btn-secondary g-mr-10 g-mb-15"
                    @click="closeVerifyPhone"
                    >Volver</v-btn
                  ></v-col
                >
                <v-col cols="12" md="6" class="text-center"
                  ><v-btn
                    type="submit"
                    class="btn-main btn btn-lg u-btn-blue g-mr-10 g-mb-15"
                    :disabled="
                      !formOtp.isValid ||
                        oneTimePasswordExpired ||
                        oneTimePasswordMaxRetries
                    "
                    :loading="formOtp.processing"
                    >Continuar 2</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </div>

          <v-card
            class="mt-4 font-color-text"
            elevation="6"
            v-if="showMessageOtpResended"
          >
            <v-card-text
              ><v-icon>mdi-alert-circle-outline</v-icon> Se acaba de enviar un
              nuevo código</v-card-text
            >
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentVerifyPhoneMixin from "@/mixins/currentVerifyPhone";

import accountsService from "@/services/accounts";
import challengesService from "@/services/challenges";

import sxRut from "sx-rut";

import { validationMixin } from "vuelidate";
import { required, minLength, sameAs } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin, baseViewMixin, currentVerifyPhoneMixin],
  props: {
    userType: {
      type: Number,
      required: true,
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    disableBackButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    showMainButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    askForPassword: {
      type: Boolean,
      required: false,
      default: true,
    },
    stopIfAccountExist: {
      type: Boolean,
      required: false,
      default: true,
    },
    preFillIfAccountExist: {
      type: Boolean,
      required: false,
      default: false,
    },
    preloadData: {
      type: Object,
      required: false,
      default: null,
    },
    disableIdentificationNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
    includePhoneVerification: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      validatePhoneSMS: false,
      formFields: {
        rut: "",
        rutRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexRut.test(value) || "No es válido",
          (value) => sxRut.isValidRut(value) || "No es válido",
        ],
        nombres: "",
        nombresRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexName.test(value) || "No es válido",
        ],
        apellidos: "",
        apellidosRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexName.test(value) || "No es válido",
        ],
        email: "",
        emailRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexEmail.test(value) || "No es válido",
        ],
        telefonoFijo: "",
        telefonoFijoRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexPhone.test(value) || "No es válido",
        ],
        telefonoCelular: "",
        telefonoCelularRules: [
          (value) => !!value || "Requerido",
          (value) => value.toString().length == 8 || "Debe contener 8 dígitos",
          (value) =>
            this.allConstants.regex.regexPhone.test(value) || "No es válido",
        ],
        clave: "",
        claveConfirmacion: "",
        accountId: null,
      },
      showPassword: false, //cuando se presiona el icono para ver la contraseña que se esta ingresando
      showPasswordConfirmacion: false, //cuando se presiona el icono para ver la repetición de contraseña que se esta ingresando

      showDialogVerifyPhone: false,
      showMessageOtpResended: false,
      oneTimePasswordExpired: false,
      oneTimePasswordMaxRetries: false,
      otpSent: false,

      formOtp: {
        isValid: false,
        processing: false,
        oneTimePassword: "",
        oneTimePasswordRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexDigits.test(value) || "No es válido",
          (value) => value.length >= 6 || "Mínimo 6 dígitos",
        ],
      },
    };
  },
  computed: {
    claveErrors() {
      const errors = [];
      if (!this.$v.formFields.clave.$dirty) return errors;
      !this.$v.formFields.clave.required && errors.push("Requerido");
      !this.$v.formFields.clave.minLength && errors.push("Mínimo 8 caracteres");

      return errors;
    },
    claveConfirmacionErrors() {
      const errors = [];
      if (!this.$v.formFields.claveConfirmacion.$dirty) return errors;
      !this.$v.formFields.claveConfirmacion.required &&
        errors.push("Requerido");
      !this.$v.formFields.claveConfirmacion.minLength &&
        errors.push("Mínimo 8 caracteres");
      !this.$v.formFields.claveConfirmacion.sameAsPassword &&
        errors.push("Claves no coinciden");

      return errors;
    },
  },
  validations: {
    formFields: {
      clave: {
        required,
        minLength: minLength(8),
      },
      claveConfirmacion: {
        required,
        minLength: minLength(8),
        sameAsPassword: sameAs("clave"),
      },
    },
  },
  watch: {
    "formFields.rut": function() {
      this.hideAlert();
    },
    /*"formFields.telefonoCelular"(newValue) {
      if (newValue != undefined || newValue != null) {
        this.verifyPhone();
      }
    },*/
    "formFields.clave": function() {
      this.hideAlert();
      /*
                this.$nextTick(() => {
                    this.$refs.basicDataForm.validate();
                });
                */
    },
  },
  methods: {
    back() {
      this.$emit("back");
    },
    verifySMS() {
      if (!this.validatePhoneSMS) {
        this.verifyPhone();
      }
    },
    async enter() {
      this.hideAlert();
      this.initSignalProcessing();

      if (
        this.stopIfAccountExist &&
        (await this.isARegisterdAccount(this.formFields.rut))
      ) {
        return;
      }

      if (
        this.askForPassword &&
        !(await this.isValidPasswordPattern(this.formFields.clave))
      ) {
        return;
      }

      const collectedData = {
        accountId: this.formFields.accountId,
        identificationNumber: this.formFields.rut,
        name: this.formFields.nombres,
        surname: this.formFields.apellidos,
        email: this.formFields.email,
        phone: this.formFields.telefonoFijo,
        cellphone: this.formFields.telefonoCelular,
        secret: this.formFields.clave,
      };

      this.$emit("enter", collectedData);
    },
    async findAccount(identificationNumber) {
      try {
        const getAccountResult = await accountsService.getByIdentification(
          identificationNumber
        );
        if (getAccountResult.data && getAccountResult.data.id != "") {
          return getAccountResult.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
    async findAccountV2(identificationNumber) {
      try {
        const getAccountResult = await accountsService.exists(
          identificationNumber
        );
        if (getAccountResult.status == 200) {
          return {};
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
    async isARegisterdAccount(identificationNumber) {
      if (!identificationNumber) return false;

      if (!sxRut.isValidRut(identificationNumber)) return false;

      const accountExistsResult = await this.findAccountV2(
        identificationNumber
      );

      if (accountExistsResult) {
        this.notify(
          `Ya existe una cuenta registrada con el rut ${this.formFields.rut}`
        );

        if (this.preFillIfAccountExist) {
          const accountFindsResult = await this.findAccount(
            identificationNumber
          );
          if (accountFindsResult != null) {
            this.formFields.nombres = accountFindsResult.account.name;
            this.formFields.apellidos = accountFindsResult.account.surname;
            this.formFields.email = accountFindsResult.account.email;
            this.formFields.telefonoFijo = accountFindsResult.account.phone;
            this.formFields.telefonoCelular =
              accountFindsResult.account.cellphone;
            this.formFields.accountId = accountFindsResult.account.id;

            this.$emit("accountExist", accountFindsResult.account);
          }
        }

        return true;
      } else return false;
    },
    async isValidPasswordPattern() {
      if (!this.formFields.clave) return false;

      const resultCheck = await accountsService.checkIsValidPasswordPattern(
        this.formFields.clave
      );
      if (!resultCheck.data.isValid) {
        this.notify("La clave no cumple con los requerimientos");
        return false;
      }

      return true;
    },
    notify(message) {
      this.stopSignalProcessing();
      this.showAlert(message);
    },
    async verifyPhone() {
      if (!this.includePhoneVerification) {
        this.enter();
        return;
      }

      if (this.formFields.telefonoCelular.toString().length != 8) return;

      if (
        !this.allConstants.regex.regexPhone.test(
          this.formFields.telefonoCelular
        )
      )
        return;

      this.hideAlert();
      this.initSignalProcessing();
      this.otpSent = false;

      this.showDialogVerifyPhone = true;

      try {
        const sendOtpResult = await this.sendOtpToPhone(
          this.formFields.telefonoCelular
        );

        if (sendOtpResult.success) {
          this.otpSent = true;
        } else {
          if (sendOtpResult.theError) {
            this.showDialogVerifyPhone = false;
            throw sendOtpResult.theError;
          }
          if (!sendOtpResult.isValidPhoneNumber) {
            this.formFields.telefonoCelular = "";
            this.showDialogVerifyPhone = false;
            this.showAlert("Número de teléfono inválido");
          }
        }
      } finally {
        this.stopSignalProcessing();
      }
    },
    async resendOtp() {
      this.hideAlert();
      this.initSignalProcessing();
      this.otpSent = false;

      try {
        const sendOtpResult = await this.resendOtpToPhone();
        if (sendOtpResult.success) {
          this.otpSent = true;
        } else {
          if (sendOtpResult.theError) {
            this.showDialogVerifyPhone = false;
            throw sendOtpResult.theError;
          }
          if (!sendOtpResult.isValidPhoneNumber) {
            this.formFields.telefonoCelular = "";
            this.showDialogVerifyPhone = false;
            this.showAlert("Número de teléfono inválido");
          }
        }
      } finally {
        this.stopSignalProcessing();
      }
    },
    async verifyOtp() {
      this.hideAlert();
      this.initSignalProcessing();

      const verifyOtpData = {
        code: this.formOtp.oneTimePassword,
        transactionId: this.currentVerifyPhone.transactionId,
      };

      const verifyOtpResult = await challengesService.verifyOtp(verifyOtpData);

      if (verifyOtpResult.data.status == this.allConstants.otp.status.invalid) {
        this.showAlert("El código ingresado no es correcto");
        this.stopSignalProcessing();
        return;
      }

      if (verifyOtpResult.data.status == this.allConstants.otp.status.expired) {
        this.oneTimePasswordExpired = true;
        this.showAlert("Código expirado");
        this.stopSignalProcessing();
        return;
      }

      if (
        verifyOtpResult.data.status == this.allConstants.otp.status.maxRetries
      ) {
        this.oneTimePasswordMaxRetries = true;
        this.showAlert("Código bloqueado por exceso de intentos fallidos");
        this.stopSignalProcessing();
        return;
      }

      this.resetOtpForm();
      this.stopSignalProcessing();
      this.enter();
    },
    closeVerifyPhone() {
      this.resetOtpForm();
      this.formFields.telefonoCelular = "";
    },
    resetOtpForm() {
      this.oneTimePasswordExpired = false;
      this.oneTimePasswordMaxRetries = false;
      this.formOtp.oneTimePassword = "";

      this.clearVerifyPhone();
      this.showDialogVerifyPhone = false;
      this.hideAlert();
    },
  },
  mounted() {
    this.formFields.telefonoFijo = "";
    this.formFields.telefonoCelular = "";
  },
  beforeMount() {
    this.formFields.telefonoFijo = "";
    this.formFields.telefonoCelular = "";

    if (this.preloadData) {
      this.formFields.rut = this.preloadData.identificationNumber;
      this.formFields.nombres = this.preloadData.name;
      this.formFields.apellidos = this.preloadData.surname;
      this.formFields.email = this.preloadData.email;
      this.formFields.telefonoFijo = this.preloadData.phone;
      this.formFields.telefonoCelular = this.preloadData.cellphone;
      this.formFields.accountId = this.preloadData.accountId;
    }
  },
};
</script>

<style lang="scss" scoped>
.headline {
  color: #199bca !important;
}
.otp-field {
  text-align: center !important;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #555;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}
.font-color-name {
  color: #555;
  font-size: 17.5px;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
}
.font-color-text {
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #555;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
}
.font-color-subtitle {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #fff !important;
}
.u-btn-blue {
  color: #fff;
  background-color: #199bca;
  width: 180px;
}
.btn {
  position: relative;
  transition: 0.2s ease;
  cursor: pointer;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.g-px-50 {
  padding-left: 3.57143rem !important;
  padding-right: 3.57143rem !important;
}
.color-orange-link {
  color: #ff9912 !important;
  font-size: 1rem !important;
}
.g-font-size-14 {
  font-size: 1rem !important;
}
.g-font-size-16 {
  font-size: 1.14286rem !important;
}
.g-color-blue {
  color: #3398dc !important;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
}
.mt-n1 {
  margin-top: -3rem !important;
}
</style>
